<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>ユーザー編集</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <p class="form-headline">ログイン情報</p>
                    <FormRow :required="true">
                      <template v-slot:label>ユーザー名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                class="form-control"
                                :class="{ 'is-error': errors.nameSei }"
                                type="text"
                                name="nameSei"
                                placeholder="姓"
                                v-trim
                                v-maxlength
                                v-space
                                maxlength="20"
                                v-model="formData.nameSei"
                              />
                            </div>
                            <div class="form-group-item">
                              <input
                                class="form-control"
                                :class="{ 'is-error': errors.nameMei }"
                                type="text"
                                name="nameMei"
                                placeholder="名"
                                v-trim
                                v-space
                                v-maxlength
                                maxlength="20"
                                v-model="formData.nameMei"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>メールアドレス / ID</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-6">
                              <input
                                class="form-control"
                                :class="{ 'is-error': errors.email }"
                                type="text"
                                name="email"
                                v-email
                                v-trim
                                v-maxlength
                                maxlength="50"
                                v-model="formData.email" />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>初回パスワード</template>
                      <template v-slot:labelNote><span class="text-note">（8文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせ) </span></template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-6">
                              <div class="form-password">
                                <input
                                  class="form-control form-password-input"
                                  :class="{ 'is-error': errors.password }"
                                  :type="pwdVisible ? 'text' : 'password'"
                                  name="password"
                                  v-trim
                                  v-model="formData.password"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" value="" v-model="pwdVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>事務局/企業情報</template>
                      <template v-slot:content v-if="!isClientChild">
                        <div class="form-content-row">
                          <ul class="listGroup">
                            <li class="listGroup-item">
                              <label class="form-radio">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  value="selected"
                                  name="typeSelectCompany"
                                  v-model="companyInputType"
                                />
                                <span class="form-radio-label">登録済みの事務局/企業から選択する</span>
                              </label>
                              <transition name="fade">
                                <div v-if="companyInputType === 'selected'" class="form-select">
                                  <SearchSelect
                                    :isCustom="true"
                                    :isError="errors.companyId"
                                    :options="allCompanies"
                                    :multiple="false"
                                    :closeOnSelect="true"
                                    :initialValue="initialCompany"
                                    @change-selection="handleSelectedCompanyId"
                                  />
                                </div>
                              </transition>
                            </li>
                            <li class="listGroup-item">
                              <label class="form-radio">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  value="created"
                                  name="typeSelectCompany"
                                  v-model="companyInputType"
                                />
                                <span class="form-radio-label">事務局/企業情報を入力する</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                      <template v-else v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">{{ currentParentCompany.name }} ＞ {{ currentChildCompany.name }}</div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <UserMailSetting v-if="companyInputType === 'selected'" :formData="formData" :disabled="isAuthEmailFlagDisabled" @updateData="updateData" />
                  </section>
                  <transition name="fade">
                    <section v-if="companyInputType === 'created'" class="form-section">
                      <p class="form-headline">事務局/企業情報</p>
                      <FormRow>
                        <template v-slot:label>親となる事務局/企業を選択</template>
                        <template v-slot:content>
                          <div class="form-content-row form-select">
                            <div class="form-group" v-if="currentParentCompany">
                              <div class="form-group-item">{{ currentParentCompany.name }}</div>
                            </div>
                            <select v-else class="form-control form-select-input" v-model="formData.company.parentId" :class="{ 'is-error': errors.parentId }">
                              <option value="">選択してください</option>
                              <option v-for="company in companies" :key="company.id" :value="company.id">
                                {{ company.name }}
                              </option>
                            </select>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>事務局/企業名</template>
                        <template v-slot:labelNote>（20文字以内）</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <input
                              class="form-control"
                              :class="{ 'is-error': errors.company.name }"
                              type="text"
                              name="companyName"
                              v-model="formData.company.name"
                              v-trim
                              v-maxlength
                              maxlength="20"
                            />
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>郵便番号</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="row">
                              <div class="col-3">
                                <input
                                  class="form-control"
                                  :class="{ 'is-error': errors.company.zipcode }"
                                  type="tel"
                                  name="zipcode"
                                  v-number
                                  maxlength="7"
                                  v-model="formData.company.zipcode"
                                  @input="getAddress"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>都道府県</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="row">
                              <div class="col-6">
                                <div class="form-select">
                                  <select
                                    class="form-control form-select-input"
                                    v-model="formData.company.prefectureId"
                                    :class="{ 'is-error': errors.company.prefectureId }"
                                  >
                                    <option value="" selected>選択してください</option>
                                    <option
                                      v-for="prefectureId in prefectures"
                                      :value="prefectureId.id"
                                      :key="prefectureId.id"
                                    >
                                      {{ prefectureId.name }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>住所</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="form-select">
                              <select
                                class="form-control form-select-input"
                                v-model="formData.company.municipalityId"
                                :class="{ 'is-error': errors.company.municipalityId }"
                              >
                                <option value="" selected>市区群町村</option>
                                <option
                                  v-for="municipalityId in municipalities"
                                  :value="municipalityId.id"
                                  :key="municipalityId.id"
                                >
                                  {{ municipalityId.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="form-content-row">
                            <input
                              class="form-control"
                              :class="{ 'is-error': errors.company.streetAddress }"
                              type="text"
                              v-trim
                              v-maxlength
                              maxlength="40"
                              name="streetAddress"
                              placeholder="丁番地"
                              v-model="formData.company.streetAddress"
                            />
                          </div>
                          <div class="form-content-row">
                            <input
                              class="form-control"
                              :class="{ 'is-error': errors.company.buildingName }"
                              type="text"
                              v-trim
                              v-maxlength
                              maxlength="40"
                              name="buildingName"
                              placeholder="建物名・号室"
                              v-model="formData.company.buildingName"
                            />
                          </div>
                        </template>
                      </FormRow>
                      <FormRow :required="true">
                        <template v-slot:label>電話番号</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="row">
                              <div class="col-3">
                                <input
                                  class="form-control"
                                  :class="{ 'is-error': errors.company.telephone }"
                                  type="tel"
                                  name="telephone"
                                  v-number
                                  v-maxlength
                                  maxlength="11"
                                  v-model="formData.company.telephone"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                    </section>
                  </transition>
                  <section class="form-section">
                    <UserMailSetting v-if="companyInputType === 'created'" :formData="formData" :disabled="isAuthEmailFlagDisabled" @updateData="updateData" />
                  </section>
                  <section class="form-section">
                    <p class="form-headline">設定条件</p>
                    <FormRow v-for="(userPrivilege, index) in userPrivilegesData" :key="index" :class="{ 'd-none': !userPrivilege.isShown }">
                      <template v-slot:label>
                        <span class="mw-190" :class="{ 'pd-20': userPrivilege.isChild }">{{ userPrivilege.name }}</span>
                      </template>
                      <template v-slot:toggle>
                        <label class="form-toggle">
                          <input
                            class="form-toggle-input"
                            :disabled="$permission.isOffice() && !userChildRoles.includes(userPrivilege.code)"
                            type="checkbox"
                            :value="userPrivilege.code"
                            v-model="formData.role"
                            @change="handleUserPrivilege"
                          />
                          <span class="form-toggle-set">
                            <span class="form-toggle-bg"></span>
                            <span class="form-toggle-circle"></span>
                          </span>
                        </label>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <TableActivites :activities="userActivities"/>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" type="button" @click="showModal('deletedModal')">削除</button>
          </li>
          <li class="listGrid-item">
            <button class="btn btn-yellow" type="button" @click="showModal('pauseModal')">{{ userDetail.status ? '一時停止' : '再開' }}</button>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'SettingUser' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-blue" :handle-submit="register" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('pauseModal')" v-if="pauseModal">
      <template v-slot:headline>{{ userDetail.status ? '一時停止' : '再開' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{
            userDetail.status
              ? `このユーザーを${appName}にログインできないようにしますか？`
              : `このユーザーを${appName}にログインできるようにしますか？`
          }}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handle-submit="changeStatus"
              :button-text="userDetail.status ? '一時停止にする' : '再開する'"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">対象のユーザーを削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('deletedModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="deleteUser" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixins
import nav from '@/mixins/nav/setting';
import edit from '@/mixins/plugin/edit';
import modal from '@/mixins/plugin/modal';
import clientUser from '@/mixins/module/clientUser';
//component
import Modal from '@/components/Modal.vue';
import TableActivites from '@/components/TableActivites.vue';

export default {
  name: 'SettingUserEditClient',
  data: function() {
    return {
      pageName: '設定',
      storeModule: 'settingUser',
      companyName: '',
      pause: true,
      pauseModal: false,
      deletedModal: false,
      initialCompany: {},
      appName: process.env.VUE_APP_APP_NAME,
    };
  },
  mixins: [nav, edit, modal, clientUser],
  components: {
    Modal,
    TableActivites,
  },
  computed: {
    ...mapGetters({
      userDetail: 'settingUser/userDetail',
    })
  },
  methods: {
    async deleteUser() {
      const result = await this.$store.dispatch('settingUser/deleteUser', this.id);
      if (result) {
        await this.$router.push({ name: 'SettingUser' });
        this.$message.deleted('accountUser');
      }
    },
    async changeStatus() {
      const result = await this.$store.dispatch('settingUser/updateStatus', { status: !this.userDetail.status ? 1 : 0 });
      this.closeModal('pauseModal');
      if (result) {
        this.$store.dispatch('settingUser/getUserDetail', this.id);
        if (this.userDetail.status) this.$message.showSuccess('reactiveUser');
        else this.$message.showSuccess('repauseUser');
      }
    },
  },
};
</script>

<style scoped>
.mw-190 {
  min-width: 190px;
}
</style>
